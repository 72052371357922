import React from "react";
import "./Footer.css";
import { Button } from "../../Button";
import { Link, useLocation } from "react-router-dom";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
  FaTiktok,
} from "react-icons/fa";
import { MdFingerprint } from "react-icons/md";
import logo from "../../images/CooMBaaH-Final-Logo.png";

function Footer() {
  const location = useLocation();
  //console.log(location.pathname);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      /*behavior: "smooth",*/
    });
  };
  return (
    <div className="footer-container">
      {/*
      <section className="footer-subscription">
        <p className="footer-subscription-heading">
          Subscribe to receive any news on CooMBaaH
        </p>
        <p className="footer-subscription-text">
          You can unsubscribe at any time.
        </p>
        <div className="input-areas">
          <form>
            <input
              className="footer-input"
              name="name"
              type="name"
              placeholder="Your Name"
            />
            <input
              className="footer-input"
              name="email"
              type="email"
              placeholder="Your Email"
            />
            <Button buttonStyle="btn--outline">Subscribe</Button>
          </form>
        </div>
      </section>
      */}
      <div className="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>
              {" "}
              <Link to="/"> Home</Link>
              {scrollToTop()}
            </h2>
          </div>
          <div className="footer-link-items">
            <h2>
              {" "}
              <Link to="/music"> Music</Link>
              {scrollToTop()}
            </h2>
          </div>

          <div className="footer-link-items">
            <h2>
              {" "}
              <Link to="/bookings"> Bookings</Link>
              {scrollToTop()}
            </h2>
          </div>
        </div>

        {/*
          <div className="footer-link-items">
            <h2>
              {" "}
              <Link to="/Fanzone"> Fan Zone</Link>
              {scrollToTop()}
            </h2>
          </div>
        </div>
    */}
      </div>
      <div className="website-rights">
        For bookings contact : music@coombaah.com
      </div>
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="social-icons">
            <Link
              className="social-icon-link"
              to="https://www.facebook.com/people/coombaah/61560467624893/"
              target="_blank"
              aria-label="Facebook"
            >
              <FaFacebook />
            </Link>
            <Link
              className="social-icon-link"
              to={"https://www.instagram.com/coombaah"}
              target="_blank"
              aria-label="Instagram"
            >
              <FaInstagram />
            </Link>
            <Link
              className="social-icon-link"
              to={"https://www.youtube.com/@CooMBaaH"}
              target="_blank"
              aria-label="Youtube"
            >
              <FaYoutube />
            </Link>
            <Link
              className="social-icon-link"
              to="https://x.com/coombaaH"
              target="_blank"
              aria-label="Twitter"
            >
              <FaTwitter />
            </Link>
            <Link
              className="social-icon-link"
              to="https://www.tiktok.com/@coombaah"
              target="_blank"
              aria-label="TikTok"
            >
              <FaTiktok />
            </Link>
          </div>
          <div className="footer-logo">
            <Link to="/" className="social-logo">
              <img
                className="footer-logo"
                src={logo}
                style={{ height: 60, width: 60 }}
              />
              CooMBaaH
            </Link>
          </div>
          {/*}
          <div className="social-icons">
            <Link
              className="social-icon-link"
              to="https://www.facebook.com/people/coombaah/61560467624893/"
              target="_blank"
              aria-label="Facebook"
            >
              <FaFacebook />
            </Link>
            <Link
              className="social-icon-link"
              to={"https://www.instagram.com/coombaah"}
              target="_blank"
              aria-label="Instagram"
            >
              <FaInstagram />
            </Link>
            <Link
              className="social-icon-link"
              to={"https://www.youtube.com/@CooMBaaH"}
              target="_blank"
              aria-label="Youtube"
            >
              <FaYoutube />
            </Link>
            <Link
              className="social-icon-link"
              to="https://x.com/coombaaH"
              target="_blank"
              aria-label="Twitter"
            >
              <FaTwitter />
            </Link>
            <Link
              className="social-icon-link"
              to="https://www.tiktok.com/@coombaah"
              target="_blank"
              aria-label="TikTok"
            >
              <FaTiktok />
            </Link>
          </div>
*/}
          <small className="website-rights">CooMBaaH © 2024</small>
          {/*
          <div className="social-icons">
            <Link
              className="social-icon-link"
              to="https://www.facebook.com/people/coombaah/61560467624893/"
              target="_blank"
              aria-label="Facebook"
            >
              <FaFacebook />
            </Link>
            <Link
              className="social-icon-link"
              to={"https://www.instagram.com/coombaah"}
              target="_blank"
              aria-label="Instagram"
            >
              <FaInstagram />
            </Link>
            <Link
              className="social-icon-link"
              to={"https://www.youtube.com/@CooMBaaH"}
              target="_blank"
              aria-label="Youtube"
            >
              <FaYoutube />
            </Link>
            <Link
              className="social-icon-link"
              to="https://x.com/coombaaH"
              target="_blank"
              aria-label="Twitter"
            >
              <FaTwitter />
            </Link>
            <Link
              className="social-icon-link"
              to="https://www.tiktok.com/@coombaah"
              target="_blank"
              aria-label="TikTok"
            >
              <FaTiktok />
            </Link>
          </div>
*/}
        </div>
      </section>
    </div>
  );
}

export default Footer;
