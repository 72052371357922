import { useState, useEffect } from "react";
import isMatch from "date-fns/isMatch";
import emailjs from "@emailjs/browser";
import moment from "moment";
import "./Bookings.css";

export default function Bookings() {
  const [formData, setFormData] = useState({
    from_name: "",
    bookingDate: "",
    email: "",
    message: "",
  });

  const [enquirySent, setEnquirysent] = useState(false);
  const [autoResponceSent, setAutoResponceSent] = useState(false);
  const [autoresponceObj, setautoresponceObj] = useState({
    to_name: "",
    to_email: "",
  });
  const [capturedEvent, setEvents] = useState("");

  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const EMAILJS_BOOKING_ENQUIRY = process.env.REACT_APP_EMAILJS_BOOKING_ENQUIRY;
  const EMAILJS_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
  const EMAILJS_BOOKING_ENQUIRY_AUTO_RESPONCE =
    process.env.REACT_APP_EMAILJS_BOOKING_ENQUIRY_AUTO_RESPONCE;

  const currentDay = new Date();
  console.log(currentDay.Date);
  //console.log(EMAILJS_BOOKING_ENQUIRY);
  //console.log("heey");

  const handleSubmit = (event) => {
    event.preventDefault();
    //alert(
    //  `Name: ${formData.name},bookingDate: ${formData.bookingdate}, Email: ${formData.email}, Message: ${formData.message}`
    //);
    setEvents(event);
    //console.log(event);
    //console.log(formData);

    sendEmail(
      EMAILJS_SERVICE_ID,
      EMAILJS_BOOKING_ENQUIRY,
      formData,
      EMAILJS_PUBLIC_KEY
    );
  };

  const validateForm = () => {
    return (
      formData.from_name.length > 1 &&
      isMatch(formData.bookingDate, "yyyy-MM-dd") &&
      isEmail(formData.email)
    );
  };

  const sendEmail = (
    EMAILJS_SERVICE_ID,
    EMAILJS_TEMPLATE_ID,
    formData,
    EMAILJS_PUBLIC_KEY
  ) => {
    //console.log(EMAILJS_SERVICE_ID);
    //console.log(EMAILJS_TEMPLATE_ID);
    //console.log(formData);
    //console.log(EMAILJS_PUBLIC_KEY);

    emailjs
      .send(
        EMAILJS_SERVICE_ID,
        EMAILJS_TEMPLATE_ID,
        formData,
        EMAILJS_PUBLIC_KEY
      )
      .then(
        () => {
          console.log("SUCCESS!");
          setEnquirysent(true);
          setautoresponceObj({
            to_name: formData.from_name,
            to_email: formData.email,
          });
        },
        (error) => {
          console.log("mail was not sent");
          //console.log(emailjs.error);
          //console.log(error.text);
        }
      );
  };
  const sendautoResponce = () => {
    //console.log(enquirySent);
    //console.log(autoResponceSent);
    //console.log("we here");
    if (enquirySent && !autoResponceSent) {
      sendEmail(
        EMAILJS_SERVICE_ID,
        EMAILJS_BOOKING_ENQUIRY_AUTO_RESPONCE,
        autoresponceObj,
        EMAILJS_PUBLIC_KEY
      );
      setEnquirysent(false);
      setAutoResponceSent(true);
      setFormData({ from_name: "", bookingDate: "", email: "", message: "" });
    }
  };
  sendautoResponce();

  return (
    <div className="container">
      <div className="base-template">
        <h1>Please Eenter The Event Details Below </h1>
      </div>

      <div className="table-responsive">
        <div className="request-form-container">
          <form action="" onSubmit={handleSubmit} acceptCharset="utf-8">
            <label htmlFor="from_name" className="formLabel">
              Organiser Name
            </label>
            <br />
            <input
              type="text"
              className="inputbox"
              name="from_name"
              value={formData.from_name}
              id="from_name"
              onChange={handleChange}
              //className={formData.from_name.length === 0 ? "error" : ""}
              placeholder="Please enter a name "
            />
            <br />
            <br />
            <label htmlFor="bookingDate" className="formLabel">
              Event Date
            </label>
            <br />
            <input
              type="date"
              className="inputbox"
              min={
                new Date(currentDay.setDate(currentDay.getDate() + 2))
                  .toISOString()
                  .split("T")[0]
              }
              name="bookingDate"
              value={formData.bookingDate}
              id="bookingDate"
              //className={
              //  !isMatch(formData.bookingDate, "yyyy-MM-dd") ? "error" : ""
              // }
              onChange={handleChange}
            />
            <br />
            <br />
            <label htmlFor="Email" className="formLabel">
              Email
            </label>
            <br />
            <input
              type="email"
              className="inputbox"
              name="email"
              value={formData.email}
              id="email"
              //className={!isEmail(formData.email) ? "error" : ""}
              placeholder="Please enter a name "
              onChange={handleChange}
            />

            <br />
            <br />
            <label htmlFor="message" className="formLabel">
              Event Details
            </label>
            <br />
            <textarea
              rows="1"
              className="messagebox"
              type="message"
              name="message"
              value={formData.message}
              id="messge"
              onChange={handleChange}
            ></textarea>
            <br />
            <br />
            <button
              name=""
              type="submit"
              id="submitButton"
              className="btn btn-default btn-default"
              disabled={!validateForm()}
            >
              Submit&nbsp;
              <span className="glyphicon glyphicon-transfer"></span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
