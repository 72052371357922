import React, { useState, useRef, useEffect } from "react";
import { passiveSupport } from "passive-events-support/src/utils";
import "./Music.css";

import ReactPlayer from "react-player";

function Music() {
  const [currentTitle, setCurrentTitle] = useState("");
  const [currentSum, setCurrentSum] = useState("");
  const [currentSummary, setCurrentSummary] = useState({});
  const [allURL, setallURL] = useState([]);
  const [alldata, setJsonData] = useState({});

  const videoUrl = useRef(null);

  const shuffle = (array) => {
    const sortedArr = structuredClone(array);
    for (let i = sortedArr.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [sortedArr[i], sortedArr[j]] = [sortedArr[j], sortedArr[i]];
    }
    return sortedArr;
  };

  const getSourceData = () => {
    const allUrls = [];
    let admins = require("./SourceData.json");
    Object.keys(admins.SourceData).forEach((key) => {
      //console.log(admins.SourceData[key].url);
      allUrls.push(admins.SourceData[key].url); //values
      //console.log(key); //keys

      const randomURL = shuffle(allUrls);
      setallURL(randomURL);

      setJsonData(admins.SourceData);
    });
  };

  passiveSupport({
    debug: false,
    // add this one
    listeners: [
      {
        element: "div.some-element",
        event: "touchstart",
      },
    ],
  });

  useEffect(
    () => {
      getSourceData(); // <- function that will run on every dependency update
    },
    [] // <-- empty dependency array
  );

  //console.log(currentTitle);
  //console.log(typeof currentTitle);
  //console.log(alldata);
  //console.log(allURL);
  //console.log(alldata[currentTitle]);
  //console.log(typeof alldata[currentTitle]);
  //console.log(currentSummary);

  //setCurrentSummary(admins.SourceData[currentTitle]);

  return (
    <>
      <div className="container">
        <div className="track_desp">{currentTitle}</div>

        <section>
          <ReactPlayer
            className="react-player"
            url={allURL}
            autoPlay
            loop
            controls
            wodth="100%"
            playing={true}
            ref={videoUrl}
            config={{
              file: {
                attributes: {
                  crossOrigin: "true",
                },
              },
            }}
            onPlay={() => {
              setCurrentTitle(
                /*videoUrl.current?.player?.player?.player?.currentSrc*/
                videoUrl.current.player.player.player.videoTitle
              );
              /*
              setCurrentURL(
                videoUrl.current.player.player.player.playerInfo.videoUrl
              );
            */
              /*console.log(videoUrl.current);
              console.log(videoUrl.current.player.player.player.videoTitle);
              console.log(
                videoUrl.current.player.player.player.playerInfo.videoUrl
              );
              */
              {
                (() => {
                  if (
                    typeof currentSummary !== "undefined" &&
                    currentSummary != null
                  ) {
                    /*return <div>someCase {currentSummary.descipt}</div>;*/
                    setCurrentSummary(alldata[currentTitle]);
                  } else {
                    setCurrentSum(alldata[currentTitle].desript);
                    console.log("Iamhere");
                  }
                })();
              }
            }}
          />
        </section>
        <div className="track_desp">
          {(() => {
            if (
              typeof currentSummary !== "undefined" &&
              currentSummary != null
            ) {
              return <div></div>;
            } else {
              try {
                //console.log(alldata[currentTitle]);
                Object.keys(alldata[currentTitle]).map((value, index) => {
                  //console.log(alldata[currentTitle].desript);
                });
                return <div> {alldata[currentTitle].desript}</div>;
              } catch (e) {
                console.log(e);
                return <div> {alldata[currentTitle]}</div>;
              }
            }
          })()}
        </div>
      </div>
    </>
  );
}

export default Music;
